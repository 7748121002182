import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

import NavbarTop from "@/components/appshell/NavbarTop.vue";
import NavDrawer from "@/components/appshell/NavDrawer.vue";

import Home from "@/views/Home.vue";
import Customers from "@/views/Customers.vue";
import VersionUpdate from "@/views/VersionUpdate.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    name: "404",
    component: () => import("@/views/404.vue"),
    meta: {
      secured: false,
      title: "Page Not Found",
    },
  },

  {
    path: "/",
    name: "Home",
    components: {
      default: Home,
    },
    meta: {
      secured: false,
      title: "Invocap Management",
    },
  },

  {
    path: "/calendar",
    name: "Calendar",
    components: {
      default: () => import("@/views/Calendar.vue"),
      top_nav: NavbarTop,
      drawer_nav: NavDrawer,
    },
    meta: {
      secured: true,
      saveRoute: true,
      title: "Invocap Team Calendar",
    },
  },

  {
    path: "/pwa-update",
    name: "VersionUpdate",
    components: {
      default: VersionUpdate,
    },
    meta: {
      secured: false,
      // saveRoute: true,
      title: "Version Update.",
    },
  },

  {
    path: "/customers",
    name: "Customers",
    components: {
      default: Customers,
      top_nav: NavbarTop,
      // bottom_nav: NavbarBottom,
      drawer_nav: NavDrawer,
    },
    meta: {
      secured: true,
      saveRoute: true,
      title: "Customers",
    },
  },

  {
    path: "/customer/on-board",
    name: "CustomerOnBoarding",
    components: {
      default: () => import("@/views/CustomerOnBoarding.vue"),
    },
    meta: {
      secured: true,
      saveRoute: true,
      title: "Customer On Boarding",
    },
  },
  {
    path: "/customers/:id",
    name: "Customer",
    components: {
      default: () => import("@/views/Customer.vue"),
      top_nav: NavbarTop,
      // bottom_nav: NavbarBottom,
      drawer_nav: NavDrawer,
    },
    meta: {
      secured: true,
      saveRoute: true,
      title: "Customer",
    },
  },


  
  {
    path: "/individuals",
    name: "Individuals",
    components: {
      default: () => import("@/views/Individuals.vue"),
      top_nav: NavbarTop,
      // bottom_nav: NavbarBottom,
      drawer_nav: NavDrawer,
    },
    meta: {
      secured: true,
      saveRoute: true,
      title: "Individuals",
    },
  },

  {
    path: "/individuals/:id",
    name: "Individual",
    components: {
      default: () => import("@/views/Individual.vue"),
      top_nav: NavbarTop,
      // bottom_nav: NavbarBottom,
      drawer_nav: NavDrawer,
    },
    meta: {
      secured: true,
      saveRoute: true,
      title: "Individual",
    },
  },


  {
    path: "/pipelines",
    name: "Pipelines",
    components: {
      default: () => import("@/views/Pipelines.vue"),
      top_nav: NavbarTop,
      // bottom_nav: NavbarBottom,
      drawer_nav: NavDrawer,
    },
    meta: {
      secured: true,
      saveRoute: true,
      title: "Loans",
    },
  },

  {
    path: "/loans/:id",
    name: "Loan",
    components: {
      default: () => import("@/views/Loan.vue"),
      top_nav: NavbarTop,
      drawer_nav: NavDrawer,
    },
    meta: {
      secured: true,
      saveRoute: true,
      title: "Loan",
    },
  },

  {
    path: "/investors",
    name: "Investors",
    components: {
      default: () => import("@/views/Investors.vue"),
      top_nav: NavbarTop,
      drawer_nav: NavDrawer,
    },
    meta: {
      secured: true,
      saveRoute: true,
      title: "Investors",
    },
  },

  {
    path: "/lenders",
    name: "Lenders",
    components: {
      default: () => import("@/views/Lenders.vue"),
      top_nav: NavbarTop,
      drawer_nav: NavDrawer,
    },
    meta: {
      secured: true,
      saveRoute: true,
      title: "Lenders",
    },
  },

  {
    path: "/lenders/:id",
    name: "Lender",
    components: {
      default: () => import("@/views/Lender.vue"),
      top_nav: NavbarTop,
      drawer_nav: NavDrawer,
    },
    meta: {
      secured: true,
      saveRoute: true,
      title: "Lender",
    },
  },



  {
    path: "/settings",
    name: "Settings",
    components: {
      default: () => import("@/views/Settings.vue"),
      top_nav: NavbarTop,
      // bottom_nav: NavbarBottom,
      drawer_nav: NavDrawer,
    },
    meta: {
      secured: true,
      saveRoute: true,
      title: "Settings",
    },
  },

  {
    path: "/reports",
    name: "Reports",
    components: {
      default: () => import("@/views/Reports.vue"),
      top_nav: NavbarTop,
      // bottom_nav: NavbarBottom,
      drawer_nav: NavDrawer,
    },
    meta: {
      secured: true,
      saveRoute: true,
      title: "Reports",
    },
  },

  {
    path: "/services",
    name: "Services",
    components: {
      default: () => import("@/views/Services.vue"),
      top_nav: NavbarTop,
      // bottom_nav: NavbarBottom,
      drawer_nav: NavDrawer,
    },
    meta: {
      secured: true,
      saveRoute: true,
      title: "Services",
    },
  },

  {
    path: "/collections",
    name: "Collections",
    components: {
      default: () => import("@/views/Collections.vue"),
      top_nav: NavbarTop,
      // bottom_nav: NavbarBottom,
      drawer_nav: NavDrawer,
    },
    meta: {
      secured: true,
      saveRoute: true,
      title: "Collections",
    },
  },

  {
    path: "/partners",
    name: "Partners",
    components: {
      default: () => import("@/views/Partners.vue"),
      top_nav: NavbarTop,
      // bottom_nav: NavbarBottom,
      drawer_nav: NavDrawer,
    },
    meta: {
      secured: true,
      saveRoute: true,
      title: "Partners",
    },
  },

  {
    path: "/properties",
    name: "Properties",
    components: {
      default: () => import("@/views/Properties.vue"),
      top_nav: NavbarTop,
      // bottom_nav: NavbarBottom,
      drawer_nav: NavDrawer,
    },
    meta: {
      secured: true,
      saveRoute: true,
      title: "Properties",
    },
  },

  {
    path: "/properties/:id",
    name: "Property",
    components: {
      default: () => import("@/views/Property.vue"),
      top_nav: NavbarTop,
      // bottom_nav: NavbarBottom,
      drawer_nav: NavDrawer,
    },
    meta: {
      secured: true,
      saveRoute: true,
      title: "Property",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeResolve((to, from, next) => {
  let title = to.meta && to.meta.title ? to.meta.title : to.name;
  document.title = title;

  if (to.meta && to.meta.saveRoute) {
    localStorage.setItem("route", to.fullPath);
  }

  if (to.meta && to.meta.secured) {
    const authorized = store.getters["authorized"];
    if (authorized) {
      next();
    } else {
      store.commit("UPDATE_USER", null);
      next("/");
    }
  } else {
    next();
  }
});

export default router;
