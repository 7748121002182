<template>
    <v-autocomplete v-model="select" :loading="loading" :items="items" item-text="Name" return-object :search-input.sync="search" no-filter hide-no-data v-bind="$attrs" v-if="!select"/>
    <v-text-field :value="selectedItem" :hint="select.Name" v-bind="$attrs" readonly clearable @click:clear="select = null" v-else/>
</template>

<script>
    import api from "@/services/api-service";
    const api_base_uri = "customers";

    export default {
        name: "CustomersAutocomplete",
        inheritAttrs: false,

        props: {
            value: {
                type: Object,
            },
        },

        data() {
            return {
                loading: false,
                items: [],
                search: null,
                select: this.value,
            }
        },
        computed: {
            selectedItem(){
                if(!this.select){return null;}
                return this.select.Name;
            },
            queryParams() {
                let sort = [{
                    Field: "Name",
                    Dir: ""
                }];
                let strParams = `?&Offset=0&Limit=10&Sort=${JSON.stringify(sort)}`;
                if (this.search && this.search.length > 0) {
                    strParams = strParams + `&Search=${this.search || ""}`;
                }
                return strParams;
            }
        },
        watch: {
            search(val) {
                const selected = (this.select && this.select.Name);
                if (val && val !== "" && val !== selected) {
                    this.querySelections(val);
                }
            },
            select(val) {
                // this.items = [];
                this.$emit("input", val);
            },
        },
        methods: {
            async querySelections(v) {
                this.loading = true;
                this.items = [];
                try {
                    const url = `/${api_base_uri}/${this.queryParams}`;
                    const response = await api.get(url,{requestId:`/${api_base_uri}/`});

                    if (response && response.status == 200 && response.data) {
                        this.total = response.data.Total;
                        this.items = response.data.Items || [];
                    }
                } catch (error) {
                    this.$Logger.error({
                        component: this.$options._componentTag,
                        method: "querySelections"
                    }, error);

                } finally {
                    this.loading = false;
                }
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>