<template>
  <v-container fill-height>
    <transition name="fade" mode="out-in">
      <v-row align="center" justify="center" fill-height v-if="!user && displayLogin">
        <v-col cols="12" md="10" xl="3">
          <v-card full-width fill-height class="card-center" elevation="20" style="min-height:30vh;">
            <v-card-title>
              <v-avatar :size="180" color="primary" class="elevation-5">
                <BrandLogo />
              </v-avatar>
            </v-card-title>

            <v-spacer/>

            <v-card-text class="login-btns">
              <v-btn :block="$vuetify.breakpoint.xs" color="primary" large @click="loginWithGoogle">
                connect using @invocap.com <v-icon right>$vuetify.icons.auth_google</v-icon>
              </v-btn>
            </v-card-text>

            <v-card-text class="text-center">
              <h5>Powered by Invocap {{ new Date().getFullYear() }}</h5>
            </v-card-text>
              
            <v-card-text class="text-center text-uppercase font-weight-bold red--text" v-if="environment != 'Production'">
                {{ environment }}
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row align="center" justify="center" fill-height v-else>
        <v-col cols="12" md="10" xl="8">
          <v-card flat full-width fill-height class="card-center" color="transparent">
            <v-card-title>
              <BrandLogoLoader :size="180" ripple />
              <h3 class="text-capitalize mt-3" v-if="user">{{ user.displayName || user.email }}</h3>
            </v-card-title>
          </v-card>
        </v-col>
      </v-row>
    </transition>
  </v-container>
</template>

<script>
  import {
    mapGetters
  } from 'vuex'

  import {
    getAuth,
    signInWithRedirect,
    signInWithPopup,
    getRedirectResult,
    GoogleAuthProvider,
    FacebookAuthProvider,
    OAuthProvider
  } from "firebase/auth";


  export default {
    name: 'Home',

    computed: {
        ...mapGetters(["user","environment"]),
    },

    data() {
      return {
        displayLogin: false
      }
    },

    methods: {
      async _signInWithProvider(provider) {
        try {
          const auth = getAuth();
          let result = await signInWithPopup(auth, provider);
          // let result = await signInWithRedirect(auth, provider);
          // console.log("_signInWithProvider:", result);
        } catch (error) {
          this.$Logger.error({
            component: this.$options._componentTag,
            method: "_signInWithProvider"
          }, error);

          // console.log("_signInWithProvider.error:", error);
        }
      },

      async _getRedirectResult() {
        try {
          const auth = getAuth();
          let result = await getRedirectResult(auth);
          // console.log("_getRedirectResult:", result);
        } catch (error) {

          this.$Logger.error({
            component: this.$options._componentTag,
            method: "_getRedirectResult"
          }, error);

          // console.log("_getRedirectResult.error:", error);
        }
      },

      loginWithGoogle() {
        const provider = new GoogleAuthProvider();
        this._signInWithProvider(provider);
      },
      loginWithFacebook() {
        const provider = new FacebookAuthProvider();
        this._signInWithProvider(provider);
      },
      loginWithApple() {
        const provider = new OAuthProvider('apple.com');
        this._signInWithProvider(provider);
      }
    },

    mounted() {
      this._getRedirectResult();
      setTimeout(() => {
        this.displayLogin = true;
      }, 3000);
    }
  }
</script>

<style scoped>
  .v-card {
    max-width: 500px;
    margin: auto;
  }

  .login-btns .v-btn {
    text-transform: initial !important;
    font-weight: bolder;
    background-color: white;
    min-width: 250px;
  }

  .login-btns>>>.v-btn__content {
    align-items: center;
    justify-content: center;
  }
</style>