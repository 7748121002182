<template>
  <v-app>
    <vue-page-transition name="fade-in-down">
      <router-view name="top_nav" class="router-view" v-model="drawer" />
    </vue-page-transition>

    <router-view name="drawer_nav" class="router-view" v-model="drawer" />

    <v-main>
      <v-progress-linear indeterminate :color="randomColor" v-if="loading" />
      <vue-page-transition :name="transitionName">
        <router-view class="router-view" />
      </vue-page-transition>
    </v-main>

    <vue-page-transition name="fade-in-up">
      <router-view name="bottom_nav" class="router-view" />
    </vue-page-transition>

    <Notifications />
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";

import api from "@/services/api-service";
import Notifications from "@/components/appshell/SysNotifications";

export default {
  name: "App",
  components: {
    Notifications,
  },

  data() {
    return {
      drawer: this.$vuetify.breakpoint.lgAndUp,
      transitionName: "fade-in-left",
    };
  },

  computed: {
    ...mapGetters([
      "user",
      "admin",
      "updateInProgress",
      "loading",
      "randomColor",
      "darkTheme",
    ]),
  },

  watch: {
    darkTheme(v) {
      this.$vuetify.theme.dark = v;
    },

    updateInProgress(v, ov) {
      if (v) {
        //this.$Logger.track(`pwa_update`, {
        //    component: this.$options.name,
        //});
        this.$router.push("/pwa-update");
      }
    },

    user(u, ov) {
      let savedRoute = localStorage.getItem("route");
      // console.log("Home.watch.user:", u,savedRoute);

      if (u) {
        this.$Logger.identify(u.uid, {
          name: u.name,
          email: u.email,
        });

        this.getTeamMember();
        if (this.$route.fullPath != savedRoute) {
          this.$router.replace(savedRoute || "/pipelines");
        }
      } else {
        this.$router.replace("/");
      }
    },

    $route(to, from) {
      const toDepth = to.path.split("/").length;
      const fromDepth = from.path.split("/").length;
      this.transitionName =
        toDepth < fromDepth ? "fade-in-right" : "fade-in-left";
    },
  },

  methods: {
    async getTeamMember() {
      try {
        const url = `/team-members/by-email/?email=${encodeURI(
          this.user.email
        )}`;
        const response = await api.get(url);

        if (response && response.status == 200 && response.data) {
          this.$store.commit("UPDATE_ADMIN", response.data);
        }
      } catch (error) {
        this.$Logger.error(
          {
            component: this.$options._componentTag,
            method: "getTeamMember",
          },
          error
        );
      }
    },
  },

  created() {
    this.$vuetify.theme.dark = this.darkTheme;
  },
};
</script>

<style lang="scss">
@import "assets/app.scss";
</style>
