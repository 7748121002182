<template>
    <v-img src="https://ukinvocap.blob.core.windows.net/marketing/logo.png" v-bind="$attrs" />
    <!-- <v-img src="https://ukinvocap.blob.core.windows.net/marketing/invocap_logo_icon.svg" v-bind="$attrs" /> -->
</template>

<script>
    export default {
        name: "BrandLogo",
        inheritAttrs: false,
    }
</script>

<style lang="scss" scoped>

</style>