import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"; //https://www.npmjs.com/package/vuex-persistedstate
import platform from "platform";

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [createPersistedState({
    paths: ['user', 'admin', 'miniDrawer','darkTheme', 'onBoardingState']
  })],

  state: {
    darkTheme: false,
    miniDrawer: false,
    updateInProgress: false,
    user: null,
    admin: null,
    onBoardingState: null,
    api_calls: 0,
    notifications: [],
  },
  getters: {
    environment(state) {
      switch (document.location.host) {
        case "localhost:8080":
          return "Development";

        case "stg-mng.invocap.co.uk":
          return "Staging";

        default:
          return "Production";
      }
    },

    device(state) {
      return {
        manufacturer: platform.manufacturer,
        product: platform.product,
        os: platform.os.toString()
      };
    },

    updateInProgress(state) {
      return state.updateInProgress;
    },

    notifications(state) {
      return state.notifications;
    },

    miniDrawer(state) {
      return state.miniDrawer;
    },

    darkTheme(state) {
      return state.darkTheme;
    },

    onBoardingState(state) {
      return state.onBoardingState;
    },
    admin(state) {
      return state.admin;
    },
    user(state) {
      return state.user;
    },
    token(state) {
      return state.user && state.user.token;
    },
    authorized(state) {
      if (!state.user) {
        return false;
      }
      if (!state.user.token) {
        return false;
      }
      if (!state.user.email || !state.user.email.endsWith('@invocap.com')) {
        return false;
      }

      return true;
    },

    loading(state) {
      return state.api_calls > 0;
    },

    randomColor() {
      const colors = ["red", "green", "blue", "purple", "pink", "teal", "indigo", "orange", "blue-grey", "cyan", "deep-purple", "light-blue", "amber", "deep-orange"];
      let color = colors[Math.floor(Math.random() * colors.length)];
      let rand = Math.random() * (4 - 1) + 1;
      return `${color} darken-${rand}`;
    }
  },
  mutations: {
    ADD_NOTIFICATION(state, payload) {
      state.notifications.push({
        id: new Date().toISOString(),
        show: true,
        auto_dismiss: true,
        ...payload
      });
    },
    REMOVE_NOTIFICATION(state, id) {
      state.notifications = state.notifications.filter(i => i.id !== id);
    },
    CLEAR_NOTIFICATIONS(state, payload) {
      state.notifications = [];
    },

    UPDATE_FOUND(state) {
      state.updateInProgress = true;
    },
    UPDATED_PWA(state) {
      state.updateInProgress = false;
    },

    UPDATE_MINI(state, mini) {
      state.miniDrawer = mini;
    },

    UPDATE_DARK(state, darkTheme) {
      state.darkTheme = darkTheme;
    },

    TOGGLE_DARK(state) {
      state.darkTheme = !state.darkTheme;
    },

    CLEAR_ONBOARDING(state) {
      state.onBoardingState = null;
    },
    UPDATE_ONBOARDING_CUSTOMER(state, payload) {
      const merged = {
        ...this.state.onBoardingState,
        customer: payload
      };
      state.onBoardingState = merged;
    },
    UPDATE_ONBOARDING_PROPERTIES(state, payload) {
      const merged = {
        ...this.state.onBoardingState,
        properties: payload
      };
      state.onBoardingState = merged;
    },
    UPDATE_ONBOARDING_LOAN(state, payload) {
      const merged = {
        ...this.state.onBoardingState,
        loan: payload
      };
      state.onBoardingState = merged;
    },

    UPDATE_USER(state, payload) {
      state.user = payload
    },

    UPDATE_ADMIN(state, payload) {
      state.admin = payload
    },

    ADD_API_CALL(state) {
      state.api_calls++;
    },
    REMOVE_API_CALL(state) {
      if (state.api_calls > 0) state.api_calls--;
    },
  },

  actions: {},
  modules: {}
})