
import Vue from 'vue'
import store from '@/store';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA9_l0hHzvV1CFW86PrDf3LTf1pArZVc5E",
  authDomain: "invocap-186712.firebaseapp.com",
  projectId: "invocap-186712",
  storageBucket: "invocap-186712.appspot.com",
  messagingSenderId: "471745126681",
  appId: "1:471745126681:web:698f2b492625958461e588",
  measurementId: "G-9ZEPX6EMK9"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
//Bind FireBase Auth State to Vuex:
const auth = getAuth();
Vue.prototype.$auth = auth;
// onAuthStateChanged(auth, user => {
//   store.commit('UPDATE_USER', user);
// });
onAuthStateChanged(auth, u => {
  if (!u) {
    store.commit('UPDATE_USER', null);
    return;
  }

  // console.log("firebase.onAuthStateChanged,", u);

  const email = u.email || u.providerData[0].email;
  const name = u.displayName || u.providerData[0].displayName;
  const phone = u.phoneNumber || u.providerData[0].phoneNumber;

  const user = {
    uid: u.uid,
    name: name,
    email: email,
    phone: phone,
    photo: u.photoURL,
    token: u.accessToken,
    provider: u.providerData[0] && u.providerData[0].providerId,
    created: u.metadata && u.metadata.creationTime
  };
  store.commit('UPDATE_USER', user);
});

export default auth;