/* eslint-disable no-console */
import store from "./store";
import { register } from 'register-service-worker';

if (process.env.NODE_ENV === 'production') {
  //if(console && console.log){
  //  console.info("PWA Disabled");
  //}
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.info(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      );
    },
    registered() {
      console.info('Service worker has been registered.');
    },
    cached() {
      console.info('Content has been cached for offline use.');
    },
    updatefound() {
      //store.commit("ADD_NOTIFICATION", {
      //  text: "Downloading new Version",
      //  timeout: 2000
      //});
      //store.commit("UPDATE_FOUND");
      console.info('New content is downloading.');
    },
    updated(registration) {
      registration.waiting.postMessage({ action: "skipWaiting" });

      let version_str = localStorage.getItem("new_version");
      let version_date = version_str != null ? Date.parse(version_str) : null;
      let version_now = new Date().getTime();
      let versions_diff_minutes = (version_now - version_date) / 1000 / 60;
      let show_notification = versions_diff_minutes > 15;

      if (!show_notification) {
        console.info('New version updated: ' + versions_diff_minutes + ' minutes ago');
        return;
      }
      console.info('New content is available; please refresh.');
      store.commit("ADD_NOTIFICATION", {
        text: "Version downloaded",
        timeout: 5000,
        btn_click: () => {
          localStorage.clear();
          localStorage.setItem("new_version", new Date().toISOString())

          window.location.reload();
        },
        btn_label: "upgrade now",
        btn_icon: "mdi-sync"
      });
      //store.commit("UPDATED_PWA");
    },
    offline() {
      console.info('No internet connection found. App is running in offline mode.');
      store.commit("ADD_NOTIFICATION", {
        text: "No internet connection found",
        timeout: 5000,
        btn_icon: "mdi-wifi-off"
      });
    },
    error(error) {
      console.error('Error during service worker registration:', error);
    }
  });
}