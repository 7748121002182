<template>
  <v-card v-bind="$attrs">
    <v-card-title class="primary--text">
      <v-btn
        fab
        small
        @click="exportReport"
        :title="`Export ${total} Customers`"
        class="mr-5"
      >
        <v-icon>$vuetify.$icons.actions_export_xsl</v-icon>
      </v-btn>
      Customers
      <v-spacer />

      <v-btn
        rounded
        color="primary"
        :to="`/customer/on-board`"
        class="mr-5"
        elevation="6"
        v-if="showAdd"
      >
        New Customer
        <v-icon right>$vuetify.$icons.actions_add</v-icon>
      </v-btn>

      <v-select
        label="Status"
        v-model="status"
        :items="selections.Status"
        v-bind="$attrs"
        outlined
        dense
        rounded
        clearable
        hide-details
        class="shrink ml-1"
      />
      <v-text-field
        v-model="search"
        append-icon="$vuetify.icons.global_search"
        label="Search"
        placeholder="Name | Representative"
        outlined
        dense
        rounded
        clearable
        hide-details
        class="shrink ml-1"
        style="min-width: 320px"
      />
    </v-card-title>

    <v-card-text>
      <v-data-table
        item-key="id"
        :headers="headers"
        :items="items"
        :options.sync="options"
        :multi-sort="true"
        :must-sort="false"
        :server-items-length="total"
        :footer-props="footerProps"
      >
        <template v-slot:footer.prepend>
          <v-switch v-model="enableDelete" label="Enable Delete" />
        </template>

        <template v-slot:body="{ items }">
          <tbody>
            <tr
              v-for="item in items"
              :key="item.Id"
              @click="openCustomer(item)"
            >
              <td>{{ item.UpdatedOn | moment('DD/MM/YYYY') }}</td>
              <td>{{ item.Status }}</td>

              <td>{{ item.Name }}</td>
              <td>{{ item.Identifier }}</td>
              <td>{{ item.Representative }}</td>
              <td>{{ item.IntroducerCompanyName }}</td>

              <td>{{ item.Email }}</td>
              <td>{{ item.Phone }}</td>

              <td>
                <v-btn
                  icon
                  @click.stop="_deleteItem(item)"
                  :disabled="!enableDelete"
                >
                  <v-icon small>$vuetify.icons.actions_delete</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import api from "@/services/api-service";
const api_base_uri = "customers";

export default {
  name: "CustomersTable",
  inheritAttrs: false,

  props: {
    showSelect: {
      type: Boolean,
      default: false,
    },
    showAdd: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loading: false,
      enableDelete: false,
      dialog: false,
      selectedItem: null,

      search: "",
      status: "",
      selections: [],
      items: [],
      total: 0,

      options: {
        itemsPerPage: 10,
        sortBy: ["UpdatedOn"],
        sortDesc: [true],
      },
      footerProps: {
        itemsPerPageOptions: [5, 10, 50, 100],
        itemsPerPageText: "",
      },
    };
  },

  computed: {
    isSelectedItemNewRecord() {
      return this.selectedItem && !this.selectedItem.Id;
    },

    headers() {
      let list = [
        // {
        //     text: "",
        //     value: "id",
        //     sortable: false,
        //     align: "center",
        // },

        {
          text: "Updated",
          value: "UpdatedOn",
        },

        {
          text: "Status",
          value: "Status",
          align: "left",
        },

        {
          text: "Name",
          value: "Name",
          align: "left",
        },

        {
          text: "Identifier",
          value: "Identifier",
          align: "left",
        },

        {
          text: "Representative",
          value: "Representative",
          align: "left",
        },

        {
          text: "Introducer",
          value: "IntroducerCompanyName",
          align: "left",
        },

        {
          text: "Email",
          value: "Email",
          align: "left",
        },

        {
          text: "Phone",
          value: "Phone",
          align: "left",
        },

        {
          text: "",
          value: "id",
          sortable: false,
          align: "center",
        },
      ];

      return list;
    },
    offset() {
      return (this.options.page - 1) * this.options.itemsPerPage;
    },
    limit() {
      return this.options.itemsPerPage;
    },
    sort() {
      let sorts = [];
      let fields = this.options.sortBy;
      let dirs = this.options.sortDesc;
      for (let index = 0; index < fields.length; index++) {
        let field = fields[index];
        let dir = dirs[index] ? "desc" : "";
        sorts.push({
          Field: field,
          Dir: dir,
        });
      }
      return sorts;
    },
    queryParams() {
      let strParams = `?Offset=${this.offset}&Limit=${this.limit}`;
      if (this.sort && this.sort.length > 0) {
        strParams = strParams + `&Sort=${JSON.stringify(this.sort)}`;
      }
      if (this.search && this.search.length > 0) {
        strParams = strParams + `&Search=${this.search || ""}`;
      }
      if (this.status != null && this.status.length > 0) {
        strParams = strParams + `&Status=${this.status || ""}`;
      }
      return strParams;
    },
    exportParams() {
      let strParams = `?Offset=0&Limit=100000`;
      if (this.sort && this.sort.length > 0) {
        strParams = strParams + `&Sort=${JSON.stringify(this.sort)}`;
      }
      if (this.search && this.search.length > 0) {
        strParams = strParams + `&Search=${this.search || ""}`;
      }
      if (this.status != null && this.status.length > 0) {
        strParams = strParams + `&Status=${this.status || ""}`;
      }
      return strParams;
    },
  },

  watch: {
    options: {
      async handler(v) {
        localStorage.setItem(
          `${this.$options._componentTag}.options`,
          JSON.stringify(v)
        );
        await this.queryItems();
      },
      deep: true,
    },

    status: {
      async handler(v) {
        this.options.page = 1;
        localStorage.setItem(`${this.$options._componentTag}.status`, v || "");
        await this.queryItems();
      },
      deep: true,
    },

    search: {
      async handler(v) {
        this.options.page = 1;
        await this.queryItems();
      },
      deep: true,
    },

    dialogInputs(v, ov) {
      if (!v) {
        this.clearDialog();
      }
    },
  },

  methods: {
    _clearDialog() {
      this.queryItems();
      this.selectedItem = null;
      this.dialog = false;
    },

    _addItem(item) {
      this.selectedItem = {};
      this.dialog = true;
    },

    _editItem(item) {
      this.selectedItem = item;
      this.dialog = true;
    },

    openCustomer(item) {
      this.$router.push(`customers/${item.Id}`);
    },

    _openBlobFile(blob, filename) {
      if (!window.navigator.msSaveOrOpenBlob) {
        // BLOB NAVIGATOR
        let url = window.URL.createObjectURL(blob);
        let link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
      } else {
        // BLOB FOR EXPLORER 11
        const url = window.navigator.msSaveOrOpenBlob(blob, filename);
      }
    },

    async fetchSelections() {
      try {
        this.loading = true;
        const response = await api.get(`${api_base_uri}/selections`);
        if (response && response.status == 200) {
          this.selections = response.data;
        }
      } catch (error) {
        this.$Logger.error(
          {
            component: this.$options._componentTag,
            method: "fetchSelections",
          },
          error
        );
        this.$emit("error", true);
      } finally {
        this.loading = false;
      }
    },

    async queryItems() {
      try {
        this.loading = true;

        const url = `/${api_base_uri}/${this.queryParams}`;
        const response = await api.get(url,{requestId:`/${api_base_uri}/`});

        if (response && response.status == 200 && response.data) {
          this.total = response.data.Total;
          this.items = response.data.Items || [];
        }
      } catch (error) {
        this.$Logger.error(
          {
            component: this.$options._componentTag,
            method: "queryItems",
          },
          error
        );
      } finally {
        this.loading = false;
      }
    },

    async _deleteItem(item) {
      if (
        !confirm("Are you SURE?!!! this action will delete Record#" + item.Id)
      ) {
        return;
      }
      try {
        this.loading = true;

        const url = `/${api_base_uri}/${item.Id}`;
        const response = await api.delete(url);

        if (response && response.status == 202) {
          this.queryItems();
        }
      } catch (error) {
        this.$Logger.error(
          {
            component: this.$options._componentTag,
            method: "deleteItem",
          },
          error
        );
      } finally {
        this.loading = false;
      }
    },

    async exportReport() {
      try {
        this.loading = true;

        const url = `/reports/customers/${this.exportParams}`;
        const response = await api.get(url, {
          responseType: "blob",
        });

        if (response && response.status == 200 && response.data) {
          let blob = new Blob([response.data], {
            type: response.data.type,
          });

          this._openBlobFile(blob, "customers.csv");
        }
      } catch (error) {
        this.$Logger.error(
          {
            component: this.$options._componentTag,
            method: "export",
          },
          error
        );
      } finally {
        this.loading = false;
      }
    },
  },

  mounted() {
    this.fetchSelections();
    const opt = localStorage.getItem(`${this.$options._componentTag}.options`);
    if (opt) {
      this.options = JSON.parse(opt);
    }
    const st = localStorage.getItem(`${this.$options._componentTag}.status`);
    if (st) {
      this.status = st;
    }
  },
};
</script>
