<template>
  <v-autocomplete
    v-model="select"
    :loading="loading"
    :items="items"
    item-text="Name"
    return-object
    :search-input.sync="search"
    no-filter
    hide-no-data
    v-bind="$attrs"
  />
</template>

<script>
import api from "@/services/api-service";
const api_base_uri = "global";

export default {
  name: "GlobalSearch",
  inheritAttrs: false,

  data() {
    return {
      loading: false,
      items: [],
      search: null,
      select: null,
    };
  },
  computed: {
    selectedItem() {
      if (!this.select) {
        return null;
      }
      return this.select.Name;
    },
    queryParams() {
      let sort = [
        {
          Field: "Id",
          Dir: "",
        },
      ];
      let strParams = `?&Offset=0&Limit=5&Sort=${JSON.stringify(sort)}`;
      if (this.search && this.search.length > 0) {
        strParams = strParams + `&Search=${this.search || ""}`;
      }
      return strParams;
    },
  },
  watch: {
    search(val) {
      const selected = this.select && this.select.Name;
      if (val && val !== "" && val !== selected) {
        this.querySelections(val);
      }
    },
    select(val) {
      this.$emit("input", val);
      //   console.log(val);
      this._handleSearchSelection(val);
    },
  },
  methods: {
    _handleSearchSelection(item) {
      if (!item) {
        return;
      }

      let url = `/`;
      switch (item.Type) {
        case "Customers":
          url = `/customers/${item.Id}`;
          break;

        case "Individuals":
          url = `/individuals/${item.Id}`;
          break;

        case "Properties":
          url = `/properties/${item.Id}`;
          break;

        default:
          url = `/pipeline/`;
          break;
      }

      // this.$router.push(url);
      window.location.href = url;
    },

    async querySelections(v) {
      this.loading = true;
      this.items = [];
      try {
        const baseurl = "/${api_base_uri}/search";
        const url = `/${api_base_uri}/search/${this.queryParams}`;
        const response = await api.get(url, { requestId: baseurl });

        if (response && response.status == 200 && response.data) {
          this.total = response.data.Total;
          this.items = response.data.Items || [];
        }
      } catch (error) {
        this.$Logger.error(
          {
            component: this.$options._componentTag,
            method: "querySelections",
          },
          error
        );
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
