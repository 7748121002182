<template>
    <v-app-bar app dense flat absolute>
        <v-app-bar-nav-icon @click.stop="toggleDrawer"></v-app-bar-nav-icon>
        <v-toolbar-title class="text-uppercase font-weight-bold red--text mr-5">{{ environment }}:</v-toolbar-title>
        <v-toolbar-title>{{ title }}</v-toolbar-title>
        <v-spacer />
        <small class="opacity mr-2">{{ admin && admin.Email }}</small>
        <v-avatar :color="randomColor" size="32" class="white--text">
            {{ admin && admin.NameInitials }}
        </v-avatar>
    </v-app-bar>
</template>

<script>
    import {
        mapGetters
    } from 'vuex'

    export default {
        name: "NavbarTop",

        props: {
            value: {
                type: Boolean,
                default: false
            },
        },

        computed: {
            ...mapGetters(["environment","admin","randomColor"]),

            title() {
                return this.$route.meta.title;
            },
        },

        methods: {
            toggleDrawer() {
                this.$emit("input", !this.value);
            }
        },

    }
</script>