const host = location.host;
const environment = process.env.NODE_ENV;
const isProductionEnvironment = environment == "production" && host != "localhost:8080";
import LogRocket from 'logrocket';
import store from '@/store'

if (isProductionEnvironment) {
  LogRocket.init('vm8nta/digital-mortgage-mng', {
    rootHostname: 'invocap.com',
    console: {
      isEnabled: isProductionEnvironment,
      shouldAggregateConsoleErrors: isProductionEnvironment,
    },
  });
}

class LogWrapper {
  identify(uid, data) {
    if (isProductionEnvironment) {
      LogRocket.identify(uid, data);
    } else {
      console.log("Logger.identify(uid, data):", uid, data);
    }
  }

  info(text, notify = true) {
    const model = {
      text: text,
      pageUrl: document.location.href,
      timestamp: new Date().toISOString(),
    };

    if (isProductionEnvironment) {
      LogRocket.log('info', model);
    } else {
      console.info("Logger.info(text):", model);
    }

    if (notify) {
      store.commit("ADD_NOTIFICATION", {
        text: err_msg,
        timeout: 10000,
        color: "info",
        multiLine: true,
        vertical: true,
        btn_icon: "mdi-information-slab-circle-outline"
      });
    }
  }

  error(data, err, notify = true) {
    let err_msg = "Ooops, somthing bad happened..";
    if (err.response) {
      // Server responded with a status other than 200 range
      err_msg = `Response error: ${err.response.status} ${err.response.data}`;
    } else if (err.request) {
      // Request was made but no response received
      err_msg = `No response received: ${err.request}`;
    } else {
      // Something happened in setting up the request
      err_msg = `Error setting up request: ${err.message}`;
    }

    const model = {
      ...data,
      pageUrl: document.location.href,
      timestamp: new Date().toISOString(),
    };

    if (isProductionEnvironment) {
      // LogRocket:
      LogRocket.captureException(err, model);
    } else {
      console.error("Logger.error(data, err):", model, err_msg);
    }

    if (notify) {
      store.commit("ADD_NOTIFICATION", {
        text: err_msg,
        timeout: 10000,
        color: "error",
        multiLine: true,
        vertical: true,
        btn_icon: "mdi-alert-circle-outline"
      });
    }
  }
}

const Logger = {
  install(Vue, options) {
    Vue.prototype.$Logger = new LogWrapper();
  }
};

export default Logger;