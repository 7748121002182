import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from "vuetify/es5/util/colors";
import { Ripple } from "vuetify/lib/directives"

Vue.use(Vuetify);

const opts =
{
//   components: {
//     VApp,
//     VNavigationDrawer,
//     VFadeTransition,
//     VLayout,
//     VCard,
//     VCardTitle,
//     VCardText,
//     VCardActions,
//     VSheet
//   },

  directives: {
    Ripple
  },

  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: colors.cyan.darken4,
        secondary: colors.grey.darken4,
        accent: colors.orange.darken1
      },
      dark: {
        primary: colors.cyan.darken2,
        secondary: colors.grey.darken1,
        accent: colors.orange.darken1
      }
    }
  },

  icons: {
    iconfont: "mdi", // default
    values: {
        pages_dashboard:"mdi-monitor-dashboard",
        pages_services:"mdi-database-sync-outline",
        pages_pipelines:"mdi-pipe",
        pages_individuals : "mdi-badge-account",
        pages_investors:"mdi-briefcase-account-outline",
        pages_lenders:"mdi-hand-coin-outline",
        pages_credit:"mdi-file-cog-outline",
        pages_reports:"mdi-microsoft-excel",
        pages_collections:"mdi-book-alert-outline",
        pages_partners:"mdi-handshake-outline",
        pages_dashboard_users:"mdi-shield-account-outline",
        pages_properties:"mdi-home-city-outline",
        pages_calendar:"mdi-calendar",

        actions_users:"mdi-account-details-outline",
        actions_file:"mdi-file",
        // actions_open:"mdi-plus",
        // actions_close:"mdi-close",
        actions_open:"mdi-chevron-up",
        actions_close:"mdi-chevron-down",
        actions_add_single:"mdi-plus",
        actions_add:"mdi-playlist-plus",
        actions_edit_single:"mdi-pencil",
        actions_edit:"mdi-playlist-edit",
        actions_send:"mdi-send",
        actions_remove:"mdi-playlist-remove",
        actions_delete:"mdi-delete-outline",
        actions_share:"mdi-share-variant-outline",
        actions_logout:"mdi-logout-variant",
        actions_maximize:"mdi-arrow-expand-right",
        actions_minimize:"mdi-arrow-expand-left",
        actions_expand:"mdi-arrow-expand-all",
        actions_download:"mdi-download",
        actions_export:"mdi-database-export-outline",
        actions_export_xsl:"mdi-microsoft-excel",
        actions_upload:"mdi-upload",
        actions_sync:"mdi-sync",
        actions_calc:"mdi-calculator",
        actions_search:"mdi-magnify",
        actions_schedule:"mdi-calendar",
        actions_link_add:"mdi-link-variant-plus",
        actions_link_remove:"mdi-link-variant-remove",

        global_contact_name:"mdi-account-outline",
        global_contact_email:"mdi-at",
        global_contact_phone:"mdi-phone-outline",
        global_address:"mdi-map-search-outline",
        global_website:"mdi-web",
        global_chat:"mdi-forum-outline",
        global_check:"mdi-check-bold",

        global_map_marker:"mdi-map-marker",
        global_required:"mdi-asterisk",
        global_search:"mdi-database-search-outline",
        global_view_dashboard:"mdi-view-dashboard-variant-outline",

        auth_google:"mdi-google",
        auth_apple:"mdi-apple",
        auth_facebook:"mdi-facebook",
        auth_email:"mdi-email-outline",
    }
  }
}

export default new Vuetify(opts);
