<template>
    <!-- <v-text-field type="text" :value="number" @change="updated" v-mask="numbersMask" v-bind="$attrs">
        <template v-slot:append>
            <slot name="append"></slot>
        </template>
    </v-text-field> -->

      <v-text-field type="number" :value="number" @input="updated" v-bind="$attrs">
        <template v-slot:append>
            <slot name="append"></slot>
        </template>
    </v-text-field>
</template>

<script>
    ///https://github.com/probil/v-mask#readme
    ///https://github.com/text-mask/text-mask/tree/master/addons/#createnumbermask
    import createNumberMask from 'text-mask-addons/dist/createNumberMask';
    const _numbersMask = createNumberMask({
        prefix: ''
    });

    export default {
        name: "InputFieldInteger",
        inheritAttrs: false,

        props: {
            value: {
                type: Number,
                default: 0
            },
        },

        watch: {
            value(v, ov) {
                this.number = v || 0;
            },
        },

        data() {
            return {
                numbersMask: _numbersMask,
                number: this.value || 0
            }
        },

        methods: {
            updated(v) {
                // const val = v ? v.replace(/,/g, '') : 0;
                // const num = val && parseFloat(val);
                // console.log("InputFieldInteger.updated(v,num)", v, num);
                this.$emit('input', Number(v));
            }
        },
    }
</script>

<style scoped>
    .v-application--is-ltr .v-text-field >>> .v-input__append-inner,
    .v-application--is-ltr .v-text-field >>> .v-input__prepend-inner {
        margin-left: auto;
        padding-left: 4px;
    }
</style>