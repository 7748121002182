<template>
    <v-sheet color="secondary" fill-height>
        <v-container fluid fill-height>
            <v-row align="center" justify="center">
                <v-col md="6" lg="4" xl="2">
                    <v-card class="card-center" fill-height color="black" dark outlined elevation="8" style="min-height:40vh">
                        <v-card-text class="pos-r">
                            <BrandLogoLoader infinite ripple />
                        </v-card-text>
                        <v-spacer />
                        <v-card-text class="shrink">
                            <h3 class="opacity-high mb-2">Please wait..</h3>
                            <CountDownTimer :time="time" :interval="100" @ended="_redirect" height="52" rounded class="timer" />
                            <h5 class="mt-2">Downloading new Version</h5>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </v-sheet>
</template>

<script>
    import CountDownTimer from "@/components/global/CountDownTimer.vue";
    export default {
        name: "VersionUpdate",

        components: {
            CountDownTimer,
        },

        data() {
            return {
                time: this._getRandomTime(8, 10) * 1000
            }
        },

        methods: {
            _getRandomTime(min, max) {
                min = Math.ceil(min);
                max = Math.floor(max);
                return Math.floor(Math.random() * (max - min) + min);
            },
            _redirect() {
                document.location.href = "/";
            }
        },

        mounted() {
            localStorage.clear();
        }
    }
</script>

<style scoped>
    .timer {
        font-size: 2rem;
        line-height: 2.8rem;
    }

    h3 {
        font-family: 'Lobster', cursive;
        font-weight: lighter;
        letter-spacing: 4px;
    }
</style>