<template>
    <v-navigation-drawer v-model="drawer" :mini-variant.sync="mini" app>
        <v-card flat tile fill-height class="card-center">
            <v-card-text>
                <v-btn icon v-if="mini">
                    <v-icon>$vuetify.icons.actions_search</v-icon>
                </v-btn>
                <GlobalSearch outlined
                              rounded
                              dense
                              placeholder="Search System"
                              hint="Customers/Individuals/Properties"
                              color="accent"
                              v-else />
                <!-- <CustomersAutocomplete
                  v-model="customer"
                  outlined
                  rounded
                  dense
                  hide-details
                  placeholder="Customer Search"
                  color="accent"
                  v-else
                /> -->
            </v-card-text>
            <v-divider />

            <v-card-text @mouseenter="mini = false" @mouseleave="mini = true">
                <v-list dense rounded>
                    <v-list-item link to="/pipelines">
                        <v-list-item-action>
                            <v-icon>$vuetify.icons.pages_pipelines</v-icon>
                        </v-list-item-action>

                        <v-list-item-content> Loans </v-list-item-content>
                    </v-list-item>

                    <v-list-item link to="/customers">
                        <v-list-item-action>
                            <v-icon>$vuetify.icons.pages_dashboard</v-icon>
                        </v-list-item-action>

                        <v-list-item-content> Customers </v-list-item-content>
                    </v-list-item>

                    <v-list-item link to="/properties">
                        <v-list-item-action>
                            <v-icon>$vuetify.icons.pages_properties</v-icon>
                        </v-list-item-action>

                        <v-list-item-content> Properties </v-list-item-content>
                    </v-list-item>

                    <v-list-item link to="/individuals">
                        <v-list-item-action>
                            <v-icon>$vuetify.icons.pages_individuals</v-icon>
                        </v-list-item-action>

                        <v-list-item-content> Individuals </v-list-item-content>
                    </v-list-item>

                    <v-list-item link to="/reports">
                        <v-list-item-action>
                            <v-icon>$vuetify.icons.pages_reports</v-icon>
                        </v-list-item-action>

                        <v-list-item-content> Reports </v-list-item-content>
                    </v-list-item>

                    <v-list-item link to="/calendar">
                        <v-list-item-action>
                            <v-icon>$vuetify.icons.pages_calendar</v-icon>
                        </v-list-item-action>

                        <v-list-item-content> Calendar </v-list-item-content>
                    </v-list-item>

                    <v-list-item link to="/settings">
                        <v-list-item-action>
                            <v-icon>$vuetify.icons.pages_credit</v-icon>
                        </v-list-item-action>

                        <v-list-item-content> Settings </v-list-item-content>
                    </v-list-item>

                    <v-list-item link to="/partners" class="mt-15">
                        <v-list-item-action>
                            <v-icon>$vuetify.icons.pages_partners</v-icon>
                        </v-list-item-action>

                        <v-list-item-content> Partners </v-list-item-content>
                    </v-list-item>

                    <v-list-item link to="/lenders">
                        <v-list-item-action>
                            <v-icon>$vuetify.icons.pages_lenders</v-icon>
                        </v-list-item-action>

                        <v-list-item-content> Lenders </v-list-item-content>
                    </v-list-item>

                    <!-- <v-list-item link to="/dashboard-users">
                        <v-list-item-action>
                            <v-icon>$vuetify.icons.pages_dashboard_users</v-icon>
                        </v-list-item-action>

                        <v-list-item-content> UMTB Users </v-list-item-content>
                    </v-list-item> -->

                    <v-list-item @click="TOGGLE_DARK" class="mt-15">
                        <v-list-item-action>
                            <v-icon>mdi-theme-light-dark</v-icon>
                        </v-list-item-action>

                        <v-list-item-content> Dark Mode </v-list-item-content>
                    </v-list-item>

                    <v-list-item @click="signOut">
                        <v-list-item-action>
                            <v-icon>$vuetify.icons.actions_logout</v-icon>
                        </v-list-item-action>

                        <v-list-item-content> Log-Out </v-list-item-content>
                    </v-list-item>

                    <v-list-item @click="clearAndReload">
                        <v-list-item-action>
                            <v-icon>$vuetify.icons.actions_sync</v-icon>
                        </v-list-item-action>

                        <v-list-item-content> Clear Caching </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-card-text>
            <v-spacer />
            <v-divider />

            <v-card-text class="pb-5">
                <BrandLogoLoader :size="mini ? 28 : 48"
                                 :gutter="2"
                                 :infinite="loading" />
            </v-card-text>
        </v-card>
    </v-navigation-drawer>
</template>

<script>
    import { mapGetters } from "vuex";
    import { mapMutations } from "vuex";
    import { getAuth, signOut } from "firebase/auth";

    import NavigatorShare from "vue-navigator-share";
    import GlobalSearch from "@/components/global/GlobalSearch";
    import CustomersAutocomplete from "@/components/customers/CustomersAutocomplete";

    export default {
        name: "NavDrawer",

        components: {
            NavigatorShare,
            CustomersAutocomplete,
            GlobalSearch
        },

        props: {
            value: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                drawer: this.value,
                mini: false,
                // mini: this.miniDrawer,
                customer: null,
            };
        },

        watch: {
            customer(v, ov) {
                if (!v) {
                    return;
                }
                window.location.href = `/customers/${v.Id}`;
            },
            drawer(v, ov) {
                this.$emit("input", v);
            },
            mini(v, ov) {
                this.$store.commit("UPDATE_MINI", v);
            },
            value(v, ov) {
                this.drawer = v;
            },
        },

        computed: {
            ...mapGetters(["user", "miniDrawer", "loading", "environment"]),

            year() {
                return new Date().getFullYear();
            },
        },

        methods: {
            ...mapMutations(["TOGGLE_DARK"]),


            clearAndReload() {
                localStorage.clear();
                location.reload();
            },

            async signOut() {
                try {
                    const auth = getAuth();
                    let result = await signOut(auth);
                    // console.log("signOut:", result);
                } catch (error) {
                    this.$Logger.error(
                        {
                            component: this.$options._componentTag,
                            method: "signOut",
                        },
                        error
                    );

                    // console.log("signOut.error:", error);
                }
            },
        },

        mounted() {
            // this.mini = this.miniDrawer;
        },
    };
</script>

<style lang="css" scoped>
    .v-navigation-drawer__content::-webkit-scrollbar {
        width: 0;
        height: 0;
    }

    .v-navigation-drawer::-webkit-scrollbar-thumb,
    .v-navigation-drawer__content::-webkit-scrollbar-thumb {
        background-color: transparent;
    }
</style>
